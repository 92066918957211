import CreateDataContext from '../hooks/contexts/create_data_context';

const SubscriptionReducer = (state, action)=>{
    switch(action?.type){
        case "updateBreadcrumb":
            return {...state, breadcrumb: action?.payload}

        case "updateLeftArchivingTypes":
            return {...state, leftArchivingTypes: action?.payload}

        default:
            return state;
    }
}

const UpdateBreadcrumb = (dispatch) => (data)=>{
    console.log("new data before updating breadcrumb  ", data);

    dispatch({
        type: "updateBreadcrumb",
        payload: data
    })
}

const UpdateLeftArchivingTypes = (dispatch) => (data)=>{
    console.log("new data before updating left archive types", data);

    dispatch({
        type: "updateLeftArchivingTypes",
        payload: data
    })
}

export const {Provider, Context} = CreateDataContext(
    SubscriptionReducer,
    {UpdateBreadcrumb, UpdateLeftArchivingTypes},
    {breadcrumb: [], leftArchivingTypes: []}
)