// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer_footerWrap__q5ir_ {\n  background-color: #f3f5f9 !important;\n  padding: 15px;\n  margin-top: 15px;\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  z-index: 1200;\n}\n\n@media (max-width: 602px) {\n  .footer_footer_right_section__2qmis ul {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    flex-direction: row;\n  }\n\n  .footer_admin_footer__2LBKP {\n    display: flex;\n    flex-direction: column !important;\n  }\n\n  .footer_menulist__3B6Qd {\n    display: flex;\n    flex-direction: column;\n  }\n}", "",{"version":3,"sources":["webpack://src/components/footer/footer.module.scss"],"names":[],"mappings":"AACA;EACI,oCAAA;EACA,aAAA;EACA,gBAAA;EACA,eAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;AAAJ;;AAGA;EAEI;IACI,WAAA;IACA,aAAA;IACA,uBAAA;IACA,mBAAA;EADN;;EAGE;IACI,aAAA;IACA,iCAAA;EAAN;;EAEE;IACI,aAAA;IACA,sBAAA;EACN;AACF","sourcesContent":["@import '../../assets/styles/variables.scss';\n.footerWrap{\n    background-color: #f3f5f9 !important;\n    padding: 15px;\n    margin-top: 15px;\n    position: fixed;\n    bottom: 0;\n    width: 100%;\n    z-index: 1200;\n}\n\n@media (max-width:602px)\n{\n    .footer_right_section ul{\n        width: 100%;\n        display: flex;\n        justify-content: center;\n        flex-direction: row;\n    }\n    .admin_footer{\n        display: flex;\n        flex-direction: column !important;\n    }\n    .menulist{\n        display: flex;\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerWrap": "footer_footerWrap__q5ir_",
	"footer_right_section": "footer_footer_right_section__2qmis",
	"admin_footer": "footer_admin_footer__2LBKP",
	"menulist": "footer_menulist__3B6Qd"
};
export default ___CSS_LOADER_EXPORT___;
