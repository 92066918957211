import moment from "moment";

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function getFilteredBy(rows, filterBy) {
  const filterKeys = Object.keys(filterBy);

  let frows = rows.filter((item) => {
    // validates all filter criteria
    return filterKeys.every((key) => {
      if (filterBy[key] !== "") {
        if (key === "name") {
          return String(item[key])
            .toLowerCase()
            .includes(String(filterBy[key]).toLowerCase());
        } else if (key === "created_time") {
          if (filterBy[key].start !== "" && filterBy[key].end !== "") {
            const date = moment(item[key]).format("YYYY-MM-DD");
            const start = moment(filterBy[key].start).format("YYYY-MM-DD");
            const end = moment(filterBy[key].end).format("YYYY-MM-DD");
            if (filterBy[key].start === filterBy[key].end) {
              return date > start && date < end;
            } else {
              return date >= start && date <= end;
            }
          } else {
            return true;
          }
        } else {
          return (
            String(filterBy[key]).toLowerCase() ===
            String(item[key]).toLowerCase()
          );
        }
      } else {
        return true;
      }
    });
  });

  return frows;
}

export function getRandomNumber() {
  const min = 1;
  const max = 100;
  const rand = min + Math.random() * (max - min);
  return rand;
}

export function getParamsFromUrl(url, key) {
  const queryString = url;
  const urlParams = new URLSearchParams(queryString);
  let val = urlParams.get(key);
  return val ? val : false;
}

export function amountFormat(nStr) {
  nStr += "";
  var x = nStr.split(".");
  var x1 = x[0];
  var x2 = x.length > 1 ? "." + x[1] : "";
  var rgx = /(\d+)(\d{3})/;
  var isNeg = false;
  if (x1 < 0) {
    x1 *= -1;
    isNeg = true;
  }
  x1 = "" + x1;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "," + "$2");
  }
  if (isNeg) {
    return "-$" + x1 + x2;
  }
  return "$" + x1 + x2;
}
