import React, { useState } from "react";
import {
  Container,
  Grid,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import "./styles/pcr_add_styles.scss";
import { useHistory, useLocation } from "react-router";

const PCRAdd = () => {
  const query = new URLSearchParams(useLocation().search);
  const invoiceNumber = query.get("invoiceNumber") || "";
  const history = useHistory();
  const [photoName, setPhotoName] = useState("");
  const [photo, setPhoto] = useState("");

  const handlePhoto = (event) => {
    setPhoto(event.target.files[0]);
    setPhotoName(event.target.files[0].name);
  };

  const redirectBack = () => {
    history.goBack();
  };

  return (
    <Container className="sectionBox pcr-add-container">
      <Typography variant="h5" className="mb-5 heading">
        PCR Add
      </Typography>
      <Grid container>
        <Grid md={2} item className="grid-item">
          <TextField
            variant="outlined"
            label="Invoice #"
            disabled
            className="full-width"
            size="small"
            value={invoiceNumber}
          ></TextField>
        </Grid>
        <Grid md={2} item className="grid-item">
          <TextField
            variant="outlined"
            label="Payment Type"
            className="full-width"
            size="small"
          ></TextField>
        </Grid>
        <Grid md={2} item className="grid-item">
          <TextField
            variant="outlined"
            label="Payment Amount"
            className="full-width"
            size="small"
          ></TextField>
        </Grid>
        <Grid md={3} item className="grid-item photo-input">
          <Button
            variant="contained"
            component="label"
            color="primary"
            size="small"
          >
            Upload File
            <input type="file" hidden onChange={handlePhoto} />
          </Button>
          <Typography class="photo-path">{photoName}</Typography>
        </Grid>
        <Grid md={3} item className="grid-item">
          <TextField
            variant="outlined"
            label="Note"
            className="full-width"
            size="small"
          ></TextField>
        </Grid>
      </Grid>
      <Grid container className="bottom-btn-container">
        <Button variant="contained" color="primary" size="small">
          Submit
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={redirectBack}
        >
          Cancel
        </Button>
      </Grid>
    </Container>
  );
};

export default PCRAdd;
