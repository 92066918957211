import React from "react";
import { Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "10px",
    paddingBottom: "10px",
    borderBottom: "1px solid #d8eae6",
    minHeight: "auto",
  },
}));

const TableToolbar = () => {
  const classes = useToolbarStyles();
  return (
    <Toolbar className={`${classes.root} themeTableToolbar`}>
      <Typography variant="h6" className="mb-5 tableTitle">
        PAYMENT CONFIRMATION REQUEST LIST
      </Typography>
    </Toolbar>
  );
};

export default TableToolbar;
