import { Container, Grid, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./footer.module.scss";
const Footer = () => (
  <div className={styles.footerWrap} style={{background: "#f3f5f9 !important"}}>
    <Container maxWidth="xl">
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={styles.admin_footer}
      >
        <Grid item md={6}>
          <Typography variant="body1">
            All copyrights reserved &copy; 2021
          </Typography>
        </Grid>
        <Grid item md={6} className={styles.footer_right_section}>
          <ul className="menulist footermenu text-right">
            <li>
              <Link to="/terms">Terms and Conditions</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="#/">User Agreement</Link>
            </li>
          </ul>
        </Grid>
      </Grid>
    </Container>
  </div>
);

export default Footer;
