import React, { useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAmountUpAlt,
  faSortAmountUp,
} from "@fortawesome/free-solid-svg-icons";
import { getComparator, stableSort } from "../../../services/helpers";

const headers = [
  { id: "payment_date", label: "Date", isSortable: true },
  { id: "payment_type", label: "Payment Type", isSortable: true },
  { id: "account_details", label: "Account Details", isSortable: true },
  { id: "amount", label: "Amount", isSortable: true },
];

const PaymentsTable = ({ data }) => {
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("");

  const sortHandler = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const renderHeaderCells = (header, index) => {
    return (
      <Th
        className="pointer"
        key={index}
        onClick={() => header.isSortable && sortHandler(header.id)}
      >
        {header.label}
        {header.isSortable && orderBy === header.id ? (
          order === "desc" ? (
            <FontAwesomeIcon className="sortIcon" icon={faSortAmountUp} />
          ) : (
            <FontAwesomeIcon className="sortIcon" icon={faSortAmountUpAlt} />
          )
        ) : null}
      </Th>
    );
  };

  const renderRowCells = (row, header) => {
    switch (header.id) {
      case "payment_date":
      case "payment_type":
      case "account_details":
      case "amount":
        return <Td>{row[header.id]}</Td>;
    }
  };

  const finalData = stableSort(data, getComparator(order, orderBy));

  return (
    <Table className="themeTable p-0">
      <Thead>
        <Tr>
          {headers.map((header, index) => {
            return renderHeaderCells(header, index);
          })}
        </Tr>
      </Thead>
      <Tbody>
        {finalData.map((row, index) => {
          return (
            <Tr key={index}>
              {headers.map((header) => {
                return renderRowCells(row, header);
              })}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default PaymentsTable;
