import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: { 500: "#61D2B4" },
    secondaryBorder: "#DEEDEA",
  },
  typography: {
    fontFamily: [
      "calibri",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": "calibri",
      },
    },
  },
});

export default theme;
