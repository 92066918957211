import React from "react";
import { Container, Typography, Button } from "@material-ui/core";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAmountUpAlt,
  faSortAmountUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  getFilteredBy,
  getComparator,
  stableSort,
} from "../../../services/helpers";
import { useHistory } from "react-router-dom";
import TableToolbar from "./table_toolbar";
import { useState } from "react";

const headers = [
  { id: "invoice_number", label: "Invoice #", isSortable: true },
  { id: "invoice_date", label: "Date", isSortable: true },
  { id: "due_date", label: "Due Date", isSortable: true },
  { id: "invoice_amount", label: "Amount", isSortable: true },
  { id: "paid_amount", label: "Paid Amount", isSortable: true },
  { id: "invoice_status", label: "Status", isSortable: true },
];

const data = [
  {
    invoice_id: 1,
    invoice_number: 100,
    invoice_status: "pending",
    invoice_date: "2019-08-24",
    created_by: "11",
    created_timestamp: "2019-08-24T14:15:22Z",
    due_date: "14:15:22Z",
    company_compact: {
      company_id: 1,
      company_name: "Google",
    },
    invoice_final_amount: 500,
    invoice_amount: 300,
    paid_amount: 200,
  },
  {
    invoice_id: 2,
    invoice_number: 101,
    invoice_status: "received",
    invoice_date: "2019-08-24",
    created_by: "11",
    created_timestamp: "2019-08-24T14:15:22Z",
    due_date: "14:15:22Z",
    company_compact: {
      company_id: 1,
      company_name: "Google",
    },
    invoice_final_amount: 500,
    invoice_amount: 300,
    paid_amount: 200,
  },
  {
    invoice_id: 3,
    invoice_number: 102,
    invoice_date: "2019-08-24",
    invoice_status: "pending",
    created_by: "11",
    created_timestamp: "2019-08-24T14:15:22Z",
    due_date: "14:15:22Z",
    company_compact: {
      company_id: 3,
      company_name: "Amazon",
    },
    invoice_final_amount: 500,
    invoice_amount: 300,
    paid_amount: 200,
  },
];

const ClientTable = (props) => {
  const history = useHistory();
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("");

  const redirectToInvoiceDetails = (invoiceId) => {
    history.push(`/invoice?invoiceId=${invoiceId}`);
  };

  const sortHandler = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const renderHeaderCells = (header, index) => {
    return (
      <Th
        className="pointer"
        key={index}
        onClick={() => header.isSortable && sortHandler(header.id)}
      >
        {header.label}
        {header.isSortable && orderBy === header.id ? (
          order === "desc" ? (
            <FontAwesomeIcon className="sortIcon" icon={faSortAmountUp} />
          ) : (
            <FontAwesomeIcon className="sortIcon" icon={faSortAmountUpAlt} />
          )
        ) : null}
      </Th>
    );
  };

  const renderRowCells = (row, header, index) => {
    switch (header.id) {
      case "invoice_status":
        return (
          <Td
            className={
              row.invoice_status.toLowerCase() === "received"
                ? "color-primary"
                : "color-red"
            }
          >
            {row[header.id]}
          </Td>
        );
      case "invoice_number":
      case "invoice_date":
      case "due_date":
      case "invoice_amount":
      case "paid_amount":
        return <Td>{row[header.id]}</Td>;
    }
  };

  const finalData =
    props?.filterBy !== ""
      ? getFilteredBy(
          stableSort(data, getComparator(order, orderBy)),
          props?.filterBy
        )
      : stableSort(data, getComparator(order, orderBy));

  return (
    <Container className="p-0">
      <TableToolbar />
      <Container className="themeTable">
        <Table>
          <Thead>
            <Tr>
              {headers.map((header, index) => renderHeaderCells(header, index))}
            </Tr>
          </Thead>
          <Tbody>
            {finalData.map((row, index) => {
              return (
                <Tr
                  key={row.invoice_id}
                  className="pointer"
                  onClick={() => redirectToInvoiceDetails(row.invoice_id)}
                >
                  {headers.map((header) => renderRowCells(row, header, index))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Container>
    </Container>
  );
};

export default ClientTable;
