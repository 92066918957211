import React from "react";
import { Container } from "@material-ui/core";
import InvoiceTable from "./components/invoice_table";
import TableFilter from "./components/table_filter";
import { useState } from "react";
import { useLocation } from "react-router";
import { useEffect } from "react";

const InvoiceList = () => {
  const query = new URLSearchParams(useLocation().search);
  const companyId = query.get("companyId") || "";
  const [filterBy, setFilterBy] = useState("");

  useEffect(() => {
    if (companyId !== "") {
      setFilterBy({ companyId: companyId, paymentStatus: "pending" });
    }
  }, []);

  const handleApplyClick = (settings) => {
    setFilterBy(settings);
  };

  return (
    <>
      {/* <Container maxWidth="lg" className="sectionBox p-0">
        <TableFilter filterBy={filterBy} onApply={handleApplyClick} />
      </Container> */}
      <Container maxWidth="lg" className="sectionBox p-0">
        <InvoiceTable filterBy={filterBy} />
      </Container>
    </>
  );
};

export default InvoiceList;
