import axiosInstance from "../axios";
import { setLoginToken, clearLoginToken /*getLoginToken*/ } from "../storage";
let BaseUrl = process.env.REACT_APP_API_URL;

const defaultHeaders = {
  "Content-Type": "application/json",
  withCredentials: true,
};
// const authHeaders = { Authorization: `Barear ${getLoginToken()}` };

export async function login(formData, onSuccess, onError) {
  try {
    const res = await axiosInstance.post(`${BaseUrl}auth/login`, formData, {
      headers: { ...defaultHeaders },
    });
    let authToken = res?.data?.data?.accessToken;
    setLoginToken(authToken);
    onSuccess && onSuccess(res);
  } catch (res_1) {
    clearLoginToken();
    onError && onError(res_1);
  }
}

export async function logout(formData, onSuccess, onError) {
  try {
    const res = await axiosInstance.get(`${BaseUrl}auth/logout`, formData, {
      headers: { ...defaultHeaders },
    });
    clearLoginToken();
    onSuccess && onSuccess(res);
  } catch (res_1) {
    clearLoginToken();
    onError && onError(res_1);
  }
}

export async function get_all_user_details(data, onSuccess, onError) {
  try {
    const res = await axiosInstance.get(`${BaseUrl}user/all_user_details`, {
      headers: {
        ...defaultHeaders,
      },
    });
    onSuccess && onSuccess(res);
  } catch (res_1) {
    onError && onError(res_1);
  }
}


export const GetUserDetails = async (data, onSuccess, onError)=>{
  try{
    console.log("Printing data from API", data);
    const res = await axiosInstance.get("/user/me", {
      "headers": {
        "Content-Type": "application/json"
      },
      withCredentials: true
    });
    console.log("Printing res from API of /user/me  ", res );

    onSuccess && onSuccess(res);

  }
  catch(err){
    console.log("Got error for /user/me  ", err);

    onError && onError(err);

  }
}

export const Logout = async (data, onSuccess, onError)=>{
  try{
    console.log("Printing data from auth services ", data);

    const res = await axiosInstance.post("/auth/logout", {}, {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    });

    console.log("Printing response of logout call from service  ", res);

    onSuccess && onSuccess(res);

  }
  catch(err){
    console.log("Got error or logout call from auth services " , err);

    onError && onError(err);
  }
}