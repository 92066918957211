import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "../styles/invoice_table_filter.scss";

const TableFilter = (props) => {
  const [companyName, setCompanyName] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [createdBy, setCreatedBy] = useState("");

  const handleCompanyName = (event) => {
    setCompanyName(event.target.value);
  };

  const handlePaymentStatus = (event) => {
    setPaymentStatus(event.target.value);
  };

  const handleCreatedBy = (event) => {
    setCreatedBy(event.target.value);
  };

  const handleApplyClick = () => {
    props?.onApply({
      companyName: companyName,
      paymentStatus: paymentStatus,
      createdBy: createdBy,
    });
  };

  return (
    <Accordion square className="invoice-table-filter">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h5">FILTERS</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          <Grid container direction="row">
            <Grid sm={3} className="filter-grid" item>
              <TextField
                size="small"
                variant="outlined"
                label="Company"
                value={companyName}
                onChange={handleCompanyName}
              ></TextField>
            </Grid>
            <Grid sm={3} className="filter-grid" item>
              <FormControl variant="outlined" size="small" className="select">
                <InputLabel id="demo-simple-select-outlined-label">
                  Payment Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  value={paymentStatus}
                  label="Payment Status"
                  onChange={handlePaymentStatus}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="Received">Received</MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid sm={3} className="filter-grid" item>
              <TextField
                size="small"
                variant="outlined"
                label="Created By"
                value={createdBy}
                onChange={handleCreatedBy}
              ></TextField>
            </Grid>
          </Grid>
          <Grid container>
            <Grid sm={4} className="filter-grid" item>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={handleApplyClick}
              >
                Apply Filters
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default TableFilter;
