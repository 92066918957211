import React, { Suspense, useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import NotFound from "../components/not_found/not_found";
import Loader from "../components/loader/loader";
import LoginUser from "./auth/login_user";
import InvoiceContainer from "./invoice/invoice_container";
import AuthContext from "../hooks/auth_context";
import PCRTable from "./payment_confirmation_request/pcr_table";
import PCRAdd from "./payment_confirmation_request/pcr_add";
import BillingList from "./billing/billing_list";
import { Provider as BillingProvider } from '../hooks/contexts/billing_context';
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import DefaultLayouts from "../layouts/default_layouts";
import {navigationRef, isReadyRef} from '../router/navigate';
import Subscribe from './subscribe/index';
import ArchiveDetail from "./archivingdetails";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import { Provider as SubscriptionProvider } from "../context/subscriptionContext";

const HomeContainer = React.lazy(() => import("./home/home.container"));

const AppRoutes = (props) => {
  const auth = useContext(AuthContext);
  const profile = auth.profile;
  const billingFinal = (props) => (
    <BillingProvider>
      <BillingList {...props} profile={profile} />
    </BillingProvider>
  );

  const Subscription = (props)=>{
    return (
      <SubscriptionProvider>
        <Subscribe {...props} profile={profile} />
      </SubscriptionProvider>
    )
  }

  const ArchivingDetails = (props)=>{
    return (
      <SubscriptionProvider>
        <ArchiveDetail {...props} profile={profile} />
      </SubscriptionProvider>
    )
  }
  // const commonRoute = [
  //   {
  //     path: "/",
  //     component: HomeContainer,
  //     exact: true,
  //     index: 0,
  //   },
  //   {
  //     path: "/home",
  //     component: HomeContainer,
  //     exact: true,
  //     index: 2,
  //   },
  //   {
  //     path: "/invoice",
  //     component: InvoiceContainer,
  //     exact: true,
  //     index: 3,
  //   },
  //   {
  //     path: "/paymentConfirmationRequestList",
  //     component: PCRTable,
  //     exact: true,
  //     index: 4,
  //   },
  //   {
  //     path: "/paymentConfirmationRequestAdd",
  //     component: PCRAdd,
  //     exact: true,
  //     index: 5,
  //   },
  //   {
  //     path: "/billing",
  //     component: billingFinal,
  //     exact: true,
  //     index: 6,
  //   },
  // ];
  const commonRoute = [
    {
      path: "/",
      component: billingFinal,
      exact: true,
      index: 0
    },
    {
      path: "/module",
      component: Subscription,
      exact: true,
      index: 1
    },
    {
      path: "/archiving/module",
      component: ArchivingDetails,
      exact: true,
      index: 2
    }
  ]
  const openRoutes = [
    ...commonRoute,
    {
      component: () => <Redirect to="/login" />,
      exact: false,
      index: 0,
      name: "Redirect",
    },
  ];
  const routes = [
    ...commonRoute,
    {
      component: () => <Redirect to="/" />,
      exact: false,
      index: 0,
      name: "Redirect",
    },
  ];
  const adminRoutes = [];

  const renderRoutes = () => {
    if (auth.status === true) {
      let allRoutes = [...routes];
      if (props?.is_admin === 1) {
        allRoutes = [...adminRoutes, ...routes];
      }
      return allRoutes.map((route) => {
        const RouteComponent = route.component;
        return (
          <Route
            key={route.index}
            path={route.path}
            render={(props) => (
                <RouteComponent profile={profile} {...props} />
            )}
            exact={route.exact}
          />
        );
      });
    } else {
      return openRoutes.map((route) => {
        const RouteComponent = route.component;
        return (
          <Route
            key={route.index}
            path={route.path}
            render={(props) => (
                <RouteComponent {...props} />
            )}
            exact={route.exact}
          />
        );
      });
    }
  };

  // const links = {
  //   config: {
  //     screens: {
  //       ComplianceBrain: "/billing",
  //     }
  //   }
  // }

  // const Stack = createNativeStackNavigator();

  return (
    <Suspense fallback={<Loader />}>
    <Header />
      <Switch>
        {renderRoutes()}
        <Route
          render={(props) => (
              <NotFound {...props} />
          )}
        />
      </Switch>
      <Footer />
    </Suspense>
    // <NavigationContainer ref={navigationRef} onReady={() => { isReadyRef.current = true; }}  linking={links}>
    //   <Stack.Navigator initialRouteName="ComplianceBrain">
    //     <Stack.Screen profile={profile} name="ComplianceBrain" component={billingFinal}/>
    //   </Stack.Navigator>
    // </NavigationContainer>
  );
};

export default withRouter(AppRoutes);
