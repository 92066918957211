/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Typography,
  Container,
  TextField,
  FormControlLabel,
  Checkbox,
  InputLabel,
  CssBaseline,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Redirect } from "react-router-dom";
import { login } from "../../services/apiservices/auth";
import { withStyles } from "@material-ui/core/styles";
import AuthContext from "../../hooks/auth_context";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});
const SignIn = (props) => {
  const auth = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [state, setState] = useState({
    formState: { username: "", password: "" },
    loading: false,
    sRedirect: false,
    remember: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name !== "") {
      setState({ ...state, formState: { ...state.formState, [name]: value } });
    }
  };

  useEffect(() => {
    if (auth.status === true) {
      setState({ ...state, sRedirect: true });
    } else if (localStorage.remember && localStorage.username !== "") {
      setState({
        ...state,
        remember: localStorage.remember,
        formState: {
          ...state.formState,
          username: localStorage.username,
          // password: localStorage.password,
        },
      });
    }
  }, []);

  // componentDidMount() {
  //   if (this.props.auth?.isLogin === true) {
  //     this.setState({ sRedirect: true });
  //   } else if (localStorage.remember && localStorage.username !== "") {
  //     this.setState({ remember: localStorage.remember });
  //     this.setInitState();
  //   }
  // }

  const onChangeCheckbox = (event) => {
    setState({ ...state, remember: event.target.checked });
  };

  const userlogin = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setState({ ...state, loading: true });
    const { remember } = state;
    const { username, password } = state.formState;
    if (username !== "" && password !== "") {
      if (remember) {
        localStorage.username = username;
        // localStorage.password = password;
        localStorage.remember = remember;
      } else {
        localStorage.removeItem("username");
        // localStorage.removeItem("password");
        localStorage.removeItem("remember");
      }

      await login(
        { email: username, password: password },
        (res) => {
          if (Boolean(res?.data?.status) === true) {
            auth.login();
            setState({ ...state, sRedirect: true, loading: false });
          } else {
            if (res?.data?.error) {
              setErrorMessage(res?.data?.error?.message);
              setState({
                ...state,
                loading: false,
              });
            }
          }
        },
        (resError) => {
          setErrorMessage(
            resError?.data?.error?.message || "Username or password not match"
          );
          setState({
            ...state,
            loading: false,
          });
        }
      );
    } else {
      setErrorMessage("Username and/or password are required");
      setState({
        ...state,
        loading: false,
      });
    }
    setState({ ...state, loading: false });
  };

  const { formState, loading, remember, sRedirect } = state;
  const { classes } = props;
  return (
    <>
      {sRedirect === true && <Redirect push={true} to="/" />}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={`${classes.paper} sectionBox`}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate>
            <div className="formFieldGroup">
              <InputLabel>Username</InputLabel>
              <TextField
                fullWidth={true}
                variant={"outlined"}
                id="username"
                required
                // label="username"
                name="username"
                onChange={handleInputChange}
                value={formState.username}
                className="input"
                autoComplete="username"
              />
            </div>
            <div className="formFieldGroup">
              <InputLabel>Password</InputLabel>
              <TextField
                fullWidth={true}
                variant={"outlined"}
                id="password"
                required
                // label="password"
                name="password"
                type="password"
                onChange={handleInputChange}
                value={formState.password}
                className="input"
                autoComplete="current-password"
              />
            </div>

            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  checked={Boolean(remember)}
                  onChange={onChangeCheckbox}
                />
              }
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={userlogin}
              disabled={loading ? true : false}
            >
              {loading ? "Loading..." : "Sign In"}
            </Button>
            {errorMessage !== "" && (
              <Typography className="text-center" color="error" variant="body2">
                {errorMessage}
              </Typography>
            )}
            {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
          </form>
        </div>
        {/* <Box mt={8}>
        <Copyright />
      </Box> */}
      </Container>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(SignIn);
