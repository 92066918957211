/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AuthContext from "../hooks/auth_context";
import AppRoutes from "./app_route";
import theme from "../theme";
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import DefaultLayouts from "../layouts/default_layouts";
import { GetUserDetails, Logout } from "../services/apiservices/auth";

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loginprofile, setLoginProfile] = useState();

  const logoutUser = ()=>{
    setAuthenticated(false);
    Logout({}, (res)=>{
      if(res.data.status === true){
        window.location.replace(process.env.REACT_APP_LOGIN_REDIRECT);
      }
    }, (err)=>{
      console.log("got errr");
    })
  }


  useEffect(async () => {

      GetUserDetails({}, (res)=>{
        console.log("Got response");

        if(res?.data?.status === true){
          if(res?.data?.data?.flag_customer === true){
            const profile = res?.data?.data;
            console.log("Printing profile before updating it,  ", profile);
            setLoginProfile(profile);
            setAuthenticated(true);
          }
          else{
            window.location.href = process?.env?.REACT_APP_SUPER_ADMIN_URL;
          }
        }

      }, (err)=>{
        console.log("Printing error from app.js  ", err);
        window.location.href = process.env.REACT_APP_LOGIN_REDIRECT;
      })

  }, []);

  return (
    authenticated ? (<AuthContext.Provider
        value={{
          status: authenticated,
          profile: loginprofile,
          logoutuser: logoutUser
        }}
      >
        <ThemeProvider theme={theme}>
          {/* <DefaultLayouts> */}
            <CssBaseline />
            <AppRoutes profile={loginprofile} is_admin={loginprofile?.is_admin} />
          {/* </DefaultLayouts> */}
        </ThemeProvider>
      </AuthContext.Provider>) : null
  );
}

export default App;
