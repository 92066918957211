import React, { useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAmountUpAlt,
  faSortAmountUp,
} from "@fortawesome/free-solid-svg-icons";
import { getComparator, stableSort } from "../../../services/helpers";

const headers = [
  { id: "date", label: "Date", isSortable: true },
  { id: "description", label: "Description", isSortable: true },
  { id: "amount", label: "Amount", isSortable: true },
];

const invoice_list = [
  {
    date: "2021-06-21",
    description: "abc",
    quantity: 2,
    rate: 20,
    amount: 100,
  },
  {
    date: "2021-06-21",
    description: "def",
    quantity: 2,
    rate: 20,
    amount: 150,
  },
];

const LineItemsTable = ({ data, total }) => {
  data = data ? data : invoice_list;
  total = total ? total : "250";
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("");

  const sortHandler = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const renderHeaderCells = (header, index) => {
    return (
      <Th
        className="pointer"
        key={index}
        onClick={() => header.isSortable && sortHandler(header.id)}
      >
        {header.label}
        {header.isSortable && orderBy === header.id ? (
          order === "desc" ? (
            <FontAwesomeIcon className="sortIcon" icon={faSortAmountUp} />
          ) : (
            <FontAwesomeIcon className="sortIcon" icon={faSortAmountUpAlt} />
          )
        ) : null}
      </Th>
    );
  };

  const renderRowCells = (row, header) => {
    switch (header.id) {
      case "date":
      case "description":
      case "amount":
        return <Td>{row[header.id]}</Td>;
    }
  };

  const finalData = stableSort(data, getComparator(order, orderBy));

  return (
    <Table className="themeTable p-0">
      <Thead>
        <Tr>
          {headers.map((header) => {
            return renderHeaderCells(header);
          })}
        </Tr>
      </Thead>
      <Tbody>
        {finalData.map((row, index) => {
          return (
            <Tr key={index}>
              {headers.map((header) => {
                return renderRowCells(row, header);
              })}
            </Tr>
          );
        })}
        <Tr>
          <Td colspan={2} className="text-right">
            Total
          </Td>
          <Td>{total}</Td>
        </Tr>
      </Tbody>
    </Table>
  );
};

export default LineItemsTable;
