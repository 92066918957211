import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import DateRangeSelect from "../../../components/date_picker/date_range_select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "../styles/billing_table_filter.scss";

const TableFilter = (props) => {
  const [type, setType] = useState("");
  const [transactionDateRange, setTransactionDateRange] = useState({
    start: "",
    end: "",
  });
  const [intervalDateRange, setIntervalDateRange] = useState({
    start: "",
    end: "",
  });

  const handleType = (event) => {
    setType(event.target.value);
  };

  const setTransactionDateRangeprop = (start, end) => {
    setTransactionDateRange({ start: start, end: end });
  };

  const setIntervalDateRangeprop = (start, end) => {
    setIntervalDateRange({ start: start, end: end });
  };

  const handleApplyClick = () => {
    var filterObj = "";
    if (type !== "") {
      filterObj = { ...filterObj, type: type };
    }
    if (transactionDateRange.start !== "") {
      filterObj = { ...filterObj, transactionDateRange: transactionDateRange };
    }
    if (intervalDateRange.end !== "") {
      filterObj = { ...filterObj, intervalDateRange: intervalDateRange };
    }
    props?.onApply(filterObj);
  };

  return (
    <Accordion square className="billing-table-filter">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className="tableTitle  ">
          FILTERS
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          <Grid container direction="row">
            <Grid sm={3} className="filter-grid" item>
              <DateRangeSelect
                label="Transaction"
                dateRange={transactionDateRange}
                setDateRangeprop={setTransactionDateRangeprop}
              />
            </Grid>
            <Grid sm={3} className="filter-grid" item>
              <DateRangeSelect
                label="Interval"
                dateRange={intervalDateRange}
                setDateRangeprop={setIntervalDateRangeprop}
              />
            </Grid>
            <Grid sm={3} className="filter-grid" item>
              <FormControl variant="outlined" size="small" className="select">
                <InputLabel id="demo-simple-select-outlined-label">
                  Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  value={type}
                  label="Type"
                  onChange={handleType}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="Cost">COST</MenuItem>
                  <MenuItem value="Credit">CREDIT</MenuItem>
                  <MenuItem value="Invoice">INVOICE</MenuItem>
                  <MenuItem value="Payment">PAYMENT</MenuItem>
                  <MenuItem value="Refund">REFUND</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid sm={4} className="filter-grid" item>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={handleApplyClick}
              >
                Apply Filters
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default TableFilter;
