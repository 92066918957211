import React from 'react';
import './style.css';
import { Dialog, DialogTitle, DialogContent, Box, Typography, Button} from '@mui/material';
import CheckIcon from '../../assets/images/check_icon.svg';
import WarningIcon from '../../assets/images/warning_icon.svg';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Authorize from '../../assets/images/Authorize.png';
const AlertDialog = (props)=>{

    return (
        <>
            <Dialog id="admin-dialog" open={props?.open}>
                {/* <Box className="dialog-title" style={{paddingLeft: '15px'}}>
                    <DialogTitle>
                        <Typography style={{color: '#000', fontSize: '25px', fontWeight: '500'}} className="dialog-title-text">{props?.title}</Typography>
                    </DialogTitle>
                </Box> */}
                <Box>
                <DialogTitle className="close_icon">
                    <CloseRoundedIcon  onClick={()=>{
                        props?.close(false, "");
                    }} />
                    </DialogTitle>
                    
                    {props?.submit==="Close"?
                    <DialogTitle className="check_icon">
                    <img src={CheckIcon} alt="warning"/>
                </DialogTitle>:props?.submit==="Yes"?
                    <DialogTitle className="warning_icon">
                        <img src={WarningIcon} alt="warning"/>
                    </DialogTitle>:
                    <DialogTitle className="warning_icon">
                        <img style={{height:"50px",width:"50px"}} src={Authorize} alt="Authorize"/>
                        </DialogTitle>
                    }
                </Box>
                {props?.content.length > 0 && <Box className="dialog-content" style={{paddingLeft: '40px', paddingRight: '40px'}}>
                    {
                        props?.content.map((value, i)=>(
                            <Typography style={{color: '#000',textAlign:"center", fontSize: '20px', fontWeight: '500'}} className={`dialog-content-text-${i}`}>{JSON.stringify(value).slice(1).toString().replace("\"", "")}</Typography>
                        ))
                    }
                </Box>}
                <Box className="dialog-actions" >
                    {props?.submit !== "Close" && <Button className="stop_archiving_dialog_cancel_button" onClick={()=>{
                        props?.close(false, "");
                    }}>Cancel</Button>}
                    <Button onClick={()=>{
                        // we have to call api here, because it can be re-authorize, authorize or create button.
                        props?.close(true, props?.submit);
                    }} className="submit-button">{props?.submit}</Button>
                </Box>
            </Dialog>
        </>
    )
};

export default AlertDialog;