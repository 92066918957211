import React from "react";
import { Toolbar, Typography, Button, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { amountFormat } from "../../../services/helpers";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "10px",
    paddingBottom: "10px",
    minHeight: "auto",
    display: "flex",
    justifyContent: "space-between"
  },
}));

const TableToolbar = (props) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar className={`${classes.root} themeTableToolbar`}>
      <Typography variant="h6" className="mb-5 tableTitle">
        BILLING
      </Typography>
      <div>
        <Typography variant="p" className="mb-5">
          <strong>Outstanding:</strong> {amountFormat(props.outstanding_amount)}
        </Typography>
        {/* <Button variant="contained" color="primary" className="ml-10">
          Make Payment
        </Button> */}
      </div>
    </Toolbar>
  );
};

export default TableToolbar;
