import React from "react";

// Creating the context object and passing the default values.
const authContext = React.createContext({
  flag_authenticated: false,
  profile: {},
  setloginProfile: () => {},
  login: () => {},
  logoutuser: () => {},
});

export default authContext;
