import React, { useEffect, useState } from "react";
import { Container, Typography, Grid, Button } from "@material-ui/core";
import "./styles/invoice_details.scss";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useHistory } from "react-router";
import LineItemsTable from "./components/line_items_table";
import PaymentsTable from "./components/payments_table";

const data = {
  id: 1,
  invoice_number: 110,
  date: "2019-08-24",
  user_id: 11,
  invoice_list: [
    {
      date: "2021-06-21",
      description: "abc",
      quantity: 2,
      rate: 20,
      amount: 100,
    },
    {
      date: "2021-06-21",
      description: "def",
      quantity: 2,
      rate: 20,
      amount: 150,
    },
  ],
  total_amount: 250,
  status: "pending",
  company: {
    company_id: 1,
    company_name: "Google",
  },
  created_by: 21,
  created_timestamp: "2019-08-24T14:15:22Z",
  due_date: "14:15:22Z",
  adjustments: [
    {
      adjustment_id: 1,
      adjustment_amount: 5,
      adjustment_amount_added_by: 21,
      adjustment_amount_timestamp: "2019-08-24T14:15:22Z",
      adjustment_reason: "fine",
    },
  ],
  payments: [
    {
      payment_type: "BANK",
      account_details: "123xxx",
      amount: 60,
      payment_date: "14:15:22Z",
      payment_maked_by: 1,
    },
  ],
};

const InvoiceDetails = ({ invoiceId }) => {
  const history = useHistory();
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    console.log("Invoice ID = " + invoiceId);
    getInvoiceDetails(invoiceId);
  }, []);

  const getInvoiceDetails = (invoiceId) => {
    if (invoiceId != 1) {
      setErrorMsg("Data not found!");
    } else {
      setErrorMsg("");
    }
  };

  const redirectToPCRAdd = () => {
    history.push(
      `/paymentConfirmationRequestAdd?invoiceNumber=${data.invoice_number}`
    );
  };

  return (
    <>
      <Container className="sectionBox invoice-details-container">
        <Container className="section_header p-0">
          <Typography variant="h5">INVOICE DETAILS</Typography>
          <Button>
            <GetAppIcon />
          </Button>
        </Container>
        {errorMsg ? (
          <p className="text-danger">{errorMsg}</p>
        ) : (
          <Grid container className="invoice-details">
            <Grid xs={3} item>
              <h5 className="title">Invoice #</h5>
              <p>{data?.invoice_number}</p>
            </Grid>
            <Grid xs={3} item>
              <h5 className="title">Date</h5>
              <p>{data?.date}</p>
            </Grid>
            <Grid xs={3} item>
              <h5 className="title">Due Date</h5>
              <p>{data?.due_date}</p>
            </Grid>
            <Grid xs={3} item>
              <h5 className="title">Status</h5>
              <p>{data?.status}</p>
            </Grid>
            <Grid xs={12} item>
              <h5 className="title">Invoice Items</h5>
              <LineItemsTable
                data={data?.invoice_list}
                total={data?.total_amount}
              />
            </Grid>
          </Grid>
        )}
      </Container>
      {!errorMsg ? (
        <>
          <Container className="sectionBox invoice-details-container">
            <Typography variant="h5">Payments</Typography>
            <Grid xs={12} item>
              <PaymentsTable data={data?.payments} />
            </Grid>
          </Container>
        </>
      ) : null}
      <Container className="p-0">
        <Button variant="contained" color="primary" onClick={redirectToPCRAdd}>
          New Payment Confirmation Request
        </Button>
      </Container>
    </>
  );
};

export default InvoiceDetails;
