// import Axios from "axios";
// import { EventEmitter } from "./event.service";
// import { getLoginToken, clearLoginToken } from "./storage";
// const axiosInstance = Axios.create();

// const errorHandler = (error) => {
//   let token = getLoginToken();
//   if (token !== "" && error?.response?.status === 401) {
//     // console.log("error interceptors", error, error.response);
//     EventEmitter.dispatch("logoutUser");
//     clearLoginToken();
//     window.location.href = "/login";
//   }
// };

// axiosInstance.interceptors.response.use(
//   (config) => {
//     return config;
//   },
//   (error) => {
//     errorHandler(error);
//     return Promise.reject(error);
//   }
// );
// export default axiosInstance;


import axios from 'axios';
const instance = axios.create({
    // baseURL: "https://api.dev.cb-compliancebrain.io/"
    // baseURL: "http://localhost:3000/"
    baseURL: process.env.REACT_APP_API_CALL_URL
});

export default instance;