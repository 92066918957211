import React, { useState } from "react";
import { Container, Typography, Link } from "@material-ui/core";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useHistory } from "react-router";
import TableToolbar from "./components/table_toolbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAmountUpAlt,
  faSortAmountUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  getFilteredBy,
  getComparator,
  stableSort,
} from "../../services/helpers";

const headers = [
  { id: "invoice_number", label: "Invoice #", isSortable: true },
  { id: "payment_type", label: "Payment Type", isSortable: true },
  { id: "amount", label: "Amount", isSortable: true },
  { id: "payment_date", label: "Payment Date", isSortable: true },
  { id: "payment_time", label: "Payment Time", isSortable: true },
  { id: "status", label: "Status", isSortable: true },
  { id: "request_note", label: "Request Note", isSortable: false },
  { id: "response_note", label: "Response Note", isSortable: false },
];

const data = [
  {
    payment_confirmation_id: 0,
    invoice_id: 1,
    invoice_number: 1000,
    payment_type: "bank",
    amount: 0,
    payment_date: "2019-08-24",
    payment_time: "14:15:22Z",
    photo: [null],
    request_note: "string",
    transaction: {
      payment_type: "bank",
      account_details: "string",
      amount: 0,
      payment_date: "2019-08-24",
      payment_maked_by: "string",
    },
    status: "In-Review",
    response_note: "string",
  },
  {
    payment_confirmation_id: 0,
    invoice_id: 2,
    invoice_number: 1001,
    payment_type: "bank",
    amount: 0,
    payment_date: "2019-08-24",
    payment_time: "14:15:22Z",
    photo: [null],
    request_note: "string",
    transaction: {
      payment_type: "bank",
      account_details: "string",
      amount: 0,
      payment_date: "2019-08-24",
      payment_maked_by: "string",
    },
    status: "Approved",
    response_note: "string",
  },
];

const PCRTable = (props) => {
  const history = useHistory();
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("");

  const redirectToInvoiceDetails = (invoiceId) => {
    history.push(`/invoice?invoiceId=${invoiceId}`);
  };

  const sortHandler = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const renderHeaderCells = (header, index) => {
    return (
      <Th
        className="pointer"
        key={index}
        onClick={() => header.isSortable && sortHandler(header.id)}
      >
        {header.label}
        {header.isSortable && orderBy === header.id ? (
          order === "desc" ? (
            <FontAwesomeIcon className="sortIcon" icon={faSortAmountUp} />
          ) : (
            <FontAwesomeIcon className="sortIcon" icon={faSortAmountUpAlt} />
          )
        ) : null}
      </Th>
    );
  };

  const renderRowCells = (row, header) => {
    switch (header.id) {
      case "invoice_number":
        return (
          <Td>
            <Link
              className="pointer"
              onClick={() => redirectToInvoiceDetails(row.invoice_id)}
            >
              {row[header.id]}
            </Link>
          </Td>
        );
      case "status":
        return (
          <Td
            className={
              row[header.id].toLowerCase() === "in-review"
                ? ""
                : row[header.id].toLowerCase() === "approved"
                ? "color-primary"
                : "color-red"
            }
          >
            {row[header.id]}
          </Td>
        );
      case "payment_type":
      case "amount":
      case "payment_date":
      case "payment_time":
      case "request_note":
      case "response_note":
        return <Td>{row[header.id]}</Td>;
    }
  };

  const finalData = props?.filterBy
    ? getFilteredBy(
        stableSort(data, getComparator(order, orderBy)),
        props?.filterBy
      )
    : stableSort(data, getComparator(order, orderBy));

  return (
    <Container className="p-0 sectionBox">
      <TableToolbar />
      <Container className="themeTable pl-20 pr-20 pb-20">
        <Table>
          <Thead>
            <Tr>
              {headers.map((header, index) => {
                return renderHeaderCells(header, index);
              })}
            </Tr>
          </Thead>
          <Tbody>
            {finalData.map((row) => {
              return (
                <Tr key={row.payment_confirmation_id}>
                  {headers.map((header) => {
                    return renderRowCells(row, header);
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Container>
    </Container>
  );
};

export default PCRTable;
