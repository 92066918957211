import React, { useState, useContext } from "react";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import "../styles/billing_table_styles.scss";
import { amountFormat } from "../../../services/helpers";
import { useEffect } from "react";
import SimpleModal from "./simple_modal";
import { get_billing_list } from "../../../services/apiservices/billing";
import { Context as BillingContext } from '../../../hooks/contexts/billing_context';

const headers = [
  { id: "transaction_date", label: "Transaction Date" },
  { id: "interval", label: "Interval" },
  { id: "description", label: "Description" },
  { id: "amount", label: "Amount" },
];



const BillingTable = (props) => {
  console.log("Printing props from billing-table", props);
  const [data, setData] = useState([]);
  const [lines, setLines] = useState();
  const [attachments, setAttachments] = useState([]);
  const { setOutstandingAmount } = useContext(BillingContext);

  const getData = () => {
    get_billing_list(
      props?.filterBy ? props.filterBy : {}, props.profile.company[0].company_id,
      (res) => {
        console.log("Response of billing list", res);
        if (res?.data?.status) {
          setAttachments(res.data.data.attachments);
          setLines(res.data?.data.transactions.length);
          var dataArr = [];
          res.data?.data?.transactions?.map((line_item) => {
            var transactionDate = moment((line_item.transaction_date === null ? line_item.interval.start : line_item.transaction_date))
              .startOf("month")
              .format("DD-MMM-YY");
            // console.log("Printing Transaction date");
            // console.log(transactionDate);
            // console.log(line_item.transaction_date);
            if (dataArr.some((item) => item.startMonthDate === transactionDate)) {
              dataArr = dataArr.map((item) => {
                if (item.startMonthDate === transactionDate) {
                  return {
                    ...item,
                    lines: [...item.lines, line_item],
                  };
                } else {
                  return { ...item };
                }
              });
            } else {
              dataArr = [
                ...dataArr,
                {
                  startMonthDate: transactionDate,
                  lines: [line_item],
                },
              ];
            }
          });
          var startingBalance = res.data?.data?.startingBalance;
          var endingBalance = startingBalance;
          //sort DataArr in the descending order of the date
          function compareAsc(a, b) {
            if (moment(a.startMonthDate) < moment(b.startMonthDate))
              return -1;
            if (moment(a.startMonthDate) > moment(b.startMonthDate))
              return 1;
            return 0;
          }
          function compareDes(a, b) {
            if (moment(a.startMonthDate) < moment(b.startMonthDate))
              return 1;
            if (moment(a.startMonthDate) > moment(b.startMonthDate))
              return -1;
            return 0;
          }
          dataArr.sort(compareAsc);
          //Start calculating starting and ending balance in reverse order
          dataArr = dataArr.map((item) => {
            item.lines.map((line_item) => {
              endingBalance += line_item.amount;
            });
            var tmpStartingBalance = startingBalance;
            startingBalance = endingBalance;
            console.log("Printng date comparison");
            console.log((moment(new Date()).format("DD-MMM-YY")));
            console.log(item.startMonthDate);
            console.log(moment(item.startMonthDate).endOf("month").format("DD-MMM-YY"));
            console.log((moment(new Date()) <
              moment(item.startMonthDate).endOf("month")));
            return {
              ...item,
              date:
                // item.startMonthDate +
                // " to " +
                // (moment(new Date()) <
                //   moment(item.startMonthDate).endOf("month")
                //   ? moment(new Date()).format("DD-MMM-YY")
                //   : moment(item.startMonthDate).endOf("month").format("DD-MMM-YY")),
                moment(item.startMonthDate).format("MMM-YY"),
              startingBalance: tmpStartingBalance,
              endingBalance: endingBalance,
            };
          });
          console.log("Printing start month date of the first element", dataArr[0].startMonthDate);
          dataArr.sort(compareDes)
          setData(dataArr);
          if (dataArr.length > 0)
            setOutstandingAmount(dataArr[0].endingBalance);
          console.log(data);
        }
      },
      (resError) => console.log(resError)
    );
  };

  useEffect(() => {
    console.log("FILTER = " + JSON.stringify(props.filterBy));
    getData();
  }, [props.filterBy]);

  const renderHeaderCells = (header, index) => {
    return <TableCell key={index}>{header.label}</TableCell>;
  };


  // const download_attachment = new Array();
  const renderRowCells = (row, header) => {
    // attachments.map((v, i) => {
    //   if (moment(v.transaction_date).format("MM") == moment(row.transaction_date).format("MM")) {
    //     download_attachment.push(attachments[i]);
    //   }
    // })
    switch (header.id) {
      case "transaction_date":
        return <TableCell>{moment(row.transaction_date).format("DD-MMM-YY")}</TableCell>
      case "interval":
        return (
          <TableCell>
            {row[header.id].start === row[header.id].end
              ? moment(row[header.id].start).format("DD-MMM-YY")
              : moment(row[header.id].start).format("DD-MMM-YY") +
              " to " +
              moment(row[header.id].end).format("DD-MMM-YY")}
          </TableCell>
        );
      case "description":
        return <TableCell>{row[header.id]}</TableCell>;
      case "amount":
        return <TableCell>{amountFormat(row[header.id])}</TableCell>;
    }
  };

  const Row = (props) => {
    const { row, expandable } = props;
    const [open, setOpen] = React.useState(props.expanded ? true : false);
    console.log("Printing Row:", row);
    console.log("Printing Attachments : ==========", attachments);
    // console.log(attachments.filter((v, i) => console.log(moment(v.transaction_date).format("MM") === moment(row.transaction_date).format("MM"))));

    console.log(attachments.filter((v, i) => row.parent_id ? v.parent_id === row.parent_id : row.lines.map((v) => v.parent_id).indexOf(v.parent_id) > -1));

    return (
      <>
        <TableRow style={{borderBottom: "2px solid #bdc3c7"}} className={expandable ? "bg-gray expandableRow" : ""}>
          {expandable ? (
            <TableCell>{row.date}</TableCell>
          ) : (
              headers.map((header) => {
                return renderRowCells(row, header);
              })
            )}
          {expandable && (
            <>
              <TableCell className="icon-cell">
                <SimpleModal data={attachments.filter((v, i) => row.parent_id ? v.parent_id === row.parent_id : row.lines.map((v) => v.parent_id).indexOf(v.parent_id) > -1)} />
                <IconButton
                  aria-label="expand row"
                  size="small"
                  className="ml-5"
                  onClick={() => {
                    console.log("Open pressed");
                    setOpen(!open); console.log(open)
                  }}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
            </>
          )}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1} className="text-right">
                <span>Ending Balance: {amountFormat(row.endingBalance)}</span>
                <Table className="mb-10">
                  <TableHead>
                    <TableRow>
                      {headers.map((header, index) =>
                        renderHeaderCells(header, index)
                      )}
                    </TableRow>
                  </TableHead>
                  {row?.lines?.map((row) => (
                    <Row key={row} row={row} expandable={false} />
                  ))}
                </Table>
                <span>
                  Starting Balance: {amountFormat(row.startingBalance)}
                </span>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const finalData = props?.filterBy !== "" ? lines : data;
  console.log("Lines = " + JSON.stringify(lines));

  console.log("FINAL = "); console.log(finalData);

  return (
    <>
      <TableContainer className="themeTable billing-table">
        {props?.filterBy ? (
          <Table className="mb-10">
            <TableHead>
              <TableRow>
                {headers.map((header, index) =>
                  renderHeaderCells(header, index)
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {finalData?.transactions?.map((row) => {
                return (
                  <TableRow>
                    <>{headers.map((header) => renderRowCells(row, header))}</>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
            <Table aria-label="collapsible table">
              <TableBody>
                {finalData.map((row, index) => (
                  <Row
                    key={row.endingBalance}
                    row={row}
                    expandable={true}
                    expanded={index === 0 ? true : false}
                  />
                ))}
              </TableBody>
            </Table>
          )}
      </TableContainer>
    </>
  );
};

export default BillingTable;
