import React from "react";
import moment from "moment";
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@material-ui/core";
import DateRangePicker from "react-bootstrap-daterangepicker";
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:

// import "bootstrap/dist/css/bootstrap.css";
// you will also need the css that comes with bootstrap-daterangepicker
import "bootstrap-daterangepicker/daterangepicker.css";
import "../../assets/styles/datepicker.scss";
import ClearIcon from "@material-ui/icons/Clear";
import datestyles from "./date_range_select.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { TextField } from "@material-ui/core";

class DateRangeSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      start: moment().subtract(1, "days"),
      end: moment(),
      inputStart: "01/01/2018",
      inputFinish: "01/01/2018",
    };
    this.handleEvent = this.handleEvent.bind(this);
    this.inputRef = React.createRef();
  }
  handleChangeS = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ inputStart: event.target.value, selected: true });
  };
  handleChangeF = (event) => {
    this.setState({ inputFinish: event.target.value, selected: true });
  };

  handleEvent(event, picker) {
    this.setState({
      inputStart: picker.startDate.format("DD/MM/YYYY"),
      inputFinish: picker.endDate.format("DD/MM/YYYY"),
      selected: true,
    });
  }

  handleCallback = (start, end) => {
    this.props.setDateRangeprop(start, end);
    this.setState({ start: start, end: end, selected: true });
  };
  onClear = (event) => {
    this.props.setDateRangeprop("", "");
    this.setState({ start: moment(), end: moment(), selected: false });
  };

  render() {
    const { start, end, selected } = this.state;
    const { label } = this.props;
    const datevalue =
      selected !== true
        ? ""
        : moment(start).format("DD/MMM/YY") === moment(end).format("DD/MMM/YY")
        ? moment(start).format("DD-MMM-YY")
        : `${moment(start).format("DD-MMM-YY")} - ${moment(end).format(
            "DD-MMM-YY" 
          )}`;
    return (
      <>
        <div className={`${datestyles.rangePickerWrap}`}>
          <DateRangePicker
            initialSettings={{
              startDate: start !== "" && moment(start).toDate(),
              endDate: end !== "" && moment(end).toDate(),

              ranges: {
                "Month to date": [
                  moment().startOf("month").toDate(),
                  moment().toDate(),
                ],
                "Previous Month": [
                  moment().subtract(1, "months").toDate(),
                  moment().toDate(),
                ],
                "Year to date": [
                  moment().startOf("year").toDate(),
                  moment().toDate(),
                ],
                "Previous Year": [
                  moment().subtract(1, "years").toDate(),
                  moment().toDate(),
                ],
              },
            }}
            onCallback={this.handleCallback}
          >
            <div id="reportrange">
              <FormControl>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder={label ? label : ""}
                  value={datevalue}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <FontAwesomeIcon
                          className="sortIcon color-gray"
                          icon={faCalendarAlt}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </div>
          </DateRangePicker>
          {selected === true && (
            <IconButton className="p-0 pl-5" onClick={this.onClear}>
              <ClearIcon />
            </IconButton>
          )}
        </div>
      </>
    );
  }
}

export default DateRangeSelect;
