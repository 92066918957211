import React, { useState, useEffect } from "react";
import InvoiceList from "./invoice_list";
import InvoiceDetails from "./invoice_details";
import { useLocation } from "react-router";

const InvoiceContainer = () => {
  const query = new URLSearchParams(useLocation().search);
  const invoiceId = query.get("invoiceId") || "";

  return (
    <>
      {invoiceId !== "" ? (
        <InvoiceDetails invoiceId={invoiceId} />
      ) : (
          <InvoiceList />
        )}
    </>
  );
};

export default InvoiceContainer;
