import React, { useState, useContext } from "react";
import { Container } from "@material-ui/core";
import BillingTable from "./components/billing_table";
import TableFilter from "./components/table_filter";
import TableToolbar from "./components/table_toolbar";
import { Context as BillingContext } from '../../hooks/contexts/billing_context';

const BillingList = (props) => {
  const { state } = useContext(BillingContext);
  console.log("Printing state in Billinglist")
  console.log(state);
  const [filterBy, setFilterBy] = useState("");

  const handleApplyClick = (settings) => {
    setFilterBy(settings);
  };

  return (
    <>
      <Container className="sectionBox p-0" style={{marginTop: '90px'}}>
        <TableToolbar outstanding_amount={state.outstanding_amount} />
      </Container>
      {/*<Container maxWidth="lg" className="sectionBox p-0">
        <TableFilter filterBy={filterBy} onApply={handleApplyClick} />
      </Container>*/}
      <Container style={{marginBottom: '120px'}} className="sectionBox p-0">
        <BillingTable profile={props.profile} filterBy={filterBy} />
      </Container>
    </>
  );
};

export default BillingList;
