import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Container, Divider, Select, MenuItem, Button, TextField, Card, CardActionArea, CardContent, Breadcrumbs, Drawer, List, ListItemButton } from '@mui/material';
import './style.scss';
import Dialog from '../../components/new dialog';
import { GetAllArchiveTypes } from '../../services/apiservices/getallarchivetype';
import { GetAuthURL } from '../../services/apiservices/getauthurl';
import Loader from '../../components/loader/loader';
import { Link, useLocation } from 'react-router-dom';
import { Context as SubscriptionContext } from "../../context/subscriptionContext";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArchivingAccountsPrefixImage from "../../assets/images/archiving-accounts-prefix-image.png";
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
const Subscribe = () => {

    const { breadcrumb, leftArchivingTypes } = useContext(SubscriptionContext).state;
    const { UpdateBreadcrumb, UpdateLeftArchivingTypes } = useContext(SubscriptionContext);

    const [flagDialogContent, setFlagDialogContent] = useState({});
    const [modules, setModules] = useState([]);
    const [flagLoader, setFlagLoader] = useState(false);
    const [activeArchiveType, setActiveArchiveType] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(true);

    const leftSectionWidth = "290px";
    const location = useLocation();
    const query = location?.search;
    const searchParamsState = new URLSearchParams(query);
    const sourceArchiving = searchParamsState?.has("source") ? searchParamsState?.get("source") : null;

    const GetArchiveTypes = () => {
        GetAllArchiveTypes({ "flag_user": true }, (res) => {
            setFlagLoader(false);
            if (res.data.status === true) {
                console.log("Status true");
                setModules(res.data.data);
            }
            else {
                console.log("Status false");
            }
        }, (err) => {
            setFlagLoader(false);
            // debugger;
            console.log("Got error while fetching data", err)
        })
    }

    useEffect(() => {
        setFlagLoader(true);
        GetArchiveTypes();
        let temp = new Array();

        temp.push({
            index: 0,
            title: "Subscribe",
            type: "text"
        });

        temp.push({
            index: 1,
            title: sourceArchiving !== null ? sourceArchiving : "Archive",
            type: "text"
        });

        UpdateBreadcrumb(temp);
        const leftSectionArchiveTypes = [{
            title: "Archive",
            selected: sourceArchiving === null ? true : (sourceArchiving === "Archive"),
            index: 0
        }, {
            title: "Social Media Archive",
            selected: sourceArchiving === "Social Media Archive",
            index: 1
        }, {
            title: "Collaborating Tools Archive",
            selected: sourceArchiving === "Collaborating Tools Archive",
            index: 2
        }];

        UpdateLeftArchivingTypes(leftSectionArchiveTypes);
        setActiveArchiveType({
            title: sourceArchiving !== null ? sourceArchiving : "Archive",
            selected: true,
            index: 0
        });
    }, [])

    const FiveMinuteCall = () => {
        const fivesecinterval = setInterval(() => {
            GetArchiveTypes();
        }, 5000);
        setTimeout(() => {
            console.log("Time out");
            clearInterval(fivesecinterval);
        }, 5000 * 60);
    }

    const Authorise = (obj) => {
        console.log("Printing object", obj);
        setFlagLoader(true);
        GetAuthURL({
                "key": "module_id",
                "value": obj.module_id
            }, (res) => {
            setFlagLoader(false);
            console.log(res.data?.data);
            if (res.data.status) {
                window.open(res.data.data, '_blank').focus();
                FiveMinuteCall();
            }
        }, (err) => {
            setFlagLoader(false);
            console.log(err)
        })
    }

    const SetDialogContent = (e, modules) => {
        console.log(e.target.innerHTML);
        setFlagDialogContent({
            status: true,
            title: `${modules?.module_name.toString().includes("Archive") ? modules?.module_name.toString().replace("Archive", "Archiving") : modules?.module_name.toString() + "   Archiving"}`,
            content: [
                `Authorise ${modules?.module_name.toString()} account to start archiving`
            ],
            submit: "Authorise",
            module: modules
        })
    }

    const ChangeLeftArchiveTypes = (event) => {
        console.log(event?.target?.title);
        let temp = leftArchivingTypes;
        temp?.map((v) => {
            if (v?.selected === true) {
                v.selected = false;
            }
            if (v?.selected === false && v?.title === event?.target?.title) {
                v.selected = true;
                setActiveArchiveType(v);
            }
        });

        UpdateLeftArchivingTypes(temp);


        let temp1 = breadcrumb;
        temp1?.pop();
        temp1?.push({
            index: 1,
            title: event?.target?.title,
            type: "text"
        });
        UpdateBreadcrumb(temp1);
    }

    return (
        <>
            {
                flagDialogContent.status === true && <Dialog open={true} title={flagDialogContent?.title} content={flagDialogContent?.content} object={flagDialogContent?.module} submit={flagDialogContent?.submit} close={(action, redirect) => {
                    if (action === true) {
                        if (redirect === "Authorise")
                            Authorise(flagDialogContent?.module);
                    }
                    else {
                        setFlagDialogContent({
                            status: false
                        })
                    }
                    setFlagDialogContent({
                        status: false
                    })
                }} />
            }
            {
                flagLoader === true && <Loader />
            }
            {/* <Box style={{ marginTop: '80px', padding: '20px', display: 'flex', flexGrow: 1, flexDirection: 'column', marginBottom: '120px' }}>
                    <Typography variant="h1" className="arhiving" style={{ fontSize: '28px', fontWeight: 'normal', color: '#333' }}>Archiving</Typography>
                    <Divider style={{ margin: '20px 0', border: '3px solid rgba(43, 60, 85, .1)', height: 0 }} />
                    <Box style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', margin: '20px 0 20px 0' }} className="module-cards cards">
                        {
                            modules.map((obj) => {
                                console.log(obj?.module_name.includes("Archive"))
                                let statuscolor = obj?.status === "Active" && obj?.label !== undefined ? "#5cbb5c" : obj?.status === "Access issue" ? "#FAAD14" : "#F01F0E";
                                let roundborder;
                                if (obj?.label === undefined) {
                                    roundborder = obj?.status ? "#5cbb5c" : obj?.status === "Access issue" ? "rgba(250, 173, 20, .2)" : "#F01F0E";
                                }
                                else {
                                    roundborder = "";
                                }
                                return (
                                    <>
                                        <Box className={`card card-${obj.module_name}`} style={{ position: 'relative' }}>
                                            <Box style={{ height: '100px', width: '100px', margin: '40px auto', display: 'flex', alignItems: 'center', position: 'relative', flexDirection: 'column' }}>
                                                <img src={obj?.module_icon} alt={`image-${obj?.module_name}`} style={{ minWidth: '80px', minHeight: '80px', maxWidth: '80px', maxHeight: '80px' }} className={`image-${obj?.status} main-image`} />
                                            </Box>
                                            <Typography style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center', marginTop: "50px", color: '#3b78ce' }}>{obj?.module_name}</Typography>
                                            {
                                                <Box className="action_buttons">
                                                    <Divider style={{ width: 'calc(100%)', height: '1.5px', background: 'rgba(0,0,0,.1)', marginLeft: '-10px', position: 'absolute', bottom: '40px' }} />
                                                    {
                                                        obj?.user.length > 0 ? <>
                                                            <Box style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                                <Link to={`/archiving/module/${obj?.module_id}`} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                                    <Button style={{ position: 'absolute', bottom: '10px', color: '#000', width: '50%', textAlign: 'center', textTransform: 'capitalize', fontSize: '12px', fontWeight: '500', height: '20px', width: '100%' }}>{obj?.user.length === 1 ? "1 Account Archiving" : `${obj?.user.length} Accounts Archiving`}</Button>
                                                                </Link>
                                                            </Box>
                                                        </> : <>
                                                            <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <Button onClick={(e) => {
                                                                    console.log(e.target.innerHTML, "===============");
                                                                    console.log(obj);
                                                                    SetDialogContent(e, obj)
                                                                    // Authorise(obj)
                                                                }} style={{ position: 'absolute', bottom: '10px', color: '#000', width: '50%', textAlign: 'center', textTransform: 'capitalize', fontSize: '12px', fontWeight: '500', height: '20px' }}>{obj?.user.length > 0 ? "Stop Archiving" : "Start Archiving"}</Button>
                                                            </Box>
                                                        </>
                                                    }
                                                </Box>}
                                        </Box>
                                    </>
                                )
                            })
                        }
                    </Box>
                </Box> */}
            <Box className="subscription_modules">
                <Box className="breadcrumb_path">
                    <Breadcrumbs className='breadcrumb_module_name' separator={<ArrowForwardIcon fontSize="small" />}>
                        {
                            breadcrumb?.map((obj, index) => {
                                if (obj?.type === "text") {
                                    return <Typography  style={{ color: "#2b3c55", fontSize: "20px", fontWeight: 500 }} key={obj?.index}>{obj?.title}</Typography>
                                }
                                else {
                                    return <Link to="/module" key={obj?.index}>{obj?.title}</Link>
                                }
                            })
                        }
                    </Breadcrumbs>
                    {drawerOpen === true ?
                    <ArrowBackIosRoundedIcon className="sidebar_toggle_button_close" onClick={() => setDrawerOpen(!drawerOpen)} /> :  <ArrowForwardIosRoundedIcon className="sidebar_toggle_button_open" onClick={() => setDrawerOpen(!drawerOpen)} />}
                </Box>
                <Box className="middle_content" style={{ display: "flex" }}>
                    <Box className={drawerOpen === true?"left_section":"left_section left_section_close"}>
                        <Drawer variant="persistent"
                        open={drawerOpen}
                        sx={{ width: leftSectionWidth, flexShrink: 0, [`& .MuiDrawer-paper`]: { width: leftSectionWidth, boxSizing: "border-box" } }} >
                            <List sx={{ overflow: "auto" }}>
                                {
                                    leftArchivingTypes?.map((obj) => {
                                        return <ListItemButton onClick={ChangeLeftArchiveTypes} key={obj?.index} title={obj?.title} selected={obj?.selected}>{obj?.title}</ListItemButton>
                                    })
                                }
                            </List>
                        </Drawer>
                    </Box>
                    <Box className={drawerOpen === true?"right_section":" right_section right_section_drawer_close"}>
                        {
                            modules?.map((obj) => {
                                return (
                                    <Box className="archive_card">
                                        <Box className='top_archive_card'>
                                            <img src={obj?.module_icon} alt={obj?.module_name} />
                                            <Typography className="archive_card_module_name">{obj?.module_name}</Typography>
                                        </Box>
                                        <Box className='bottom_archive_card'>
                                            <Box className="archiving_accounts">
                                                <img src={ArchivingAccountsPrefixImage} alt="number-of-archiving-accounts-prefix" />
                                                <Typography className='number_archiving_accounts'>Archiving Accounts: {obj?.user?.length}</Typography>
                                            </Box>
                                            {
                                                obj?.user?.length > 0 ? <Link to={`/archiving/module?module_id=${obj?.module_id}&source=${activeArchiveType?.title}`}>
                                                    <Button>{obj?.user?.length === 0 ? "Start Archiving" : "View Accounts"}
                                                    </Button>
                                                </Link> : <Button onClick={(e) => {
                                                    SetDialogContent(e, obj);
                                                }}>{obj?.user?.length === 0 ? "Start Archiving" : "View Accounts"}</Button>
                                            }

                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Box>
            </Box>
        </>
    )
};

export default Subscribe;
