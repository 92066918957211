import React, { useEffect, useState, useContext } from "react";
import CommonContext from "../hooks/common_context";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";

const DefaultLayouts = (props) => {
  const [layoutLoader, setLayoutLoader] = useState(false);
  return (
    <>
      <CommonContext.Provider
        value={{
          loader: layoutLoader,
          setLoader: setLayoutLoader,
        }}
      >
        <Header />
        <div style={{ height: 'auto' }} className="content-wrap">
          {props.children}
          <Footer />
        </div>

      </CommonContext.Provider>
    </>
  );
};

export default DefaultLayouts;
