import { Container } from "@material-ui/core";
import React from "react";
import styles from "./not_found.module.scss";

const NotFound = () => (
  <Container maxWidth="lg" className="sectionBox">
    <h3 className={` text-center ${styles.notfoundTitle}`}>Not Found</h3>
  </Container>
);

export default NotFound;
