import axiosInstance from "../axios";
// import { BaseUrl } from "../config";
// const defaultHeaders = { "Content-Type": "application/json" };

export async function get_billing_list(filter, companyId, onSuccess, onError) {
  try {
    var queryParams = `company_id=${companyId}`;
    // if (filter?.type !== "")
    //   queryParams += "transaction_type=" + filter.type + "&";
    if (filter?.intervalDateRange?.start) {
      queryParams +=
        "interval_start_date=" +
        filter.intervalDateRange?.start +
        "&interval_end_date=" +
        filter.intervalDateRange?.end         // "&";
    }
    if (filter?.transactionDateRange?.start) {
      queryParams +=
        "transaction_start_date=" +
        filter.transactionDateRange?.start +
        "&transaction_end_date=" +
        filter.transactionDateRange?.end;
    }
    if (queryParams !== "") queryParams = "?" + queryParams;
    const res = await axiosInstance.get(
      `/api/billing/transaction${queryParams}`, {
        headers : {
          "Content-Type": "application/json"
        },
          "withCredentials": true
      }
    );
    onSuccess && onSuccess(res);
  } catch (res_1) {
    onError && onError(res_1);
  }
}
