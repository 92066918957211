import axios from '../axios';

export const GetAuthURL = async (data, onSuccess, onError)=>{
    try{
        let params = "";
        params += `${data.key}=${data.value}`;
        console.log("Printing params", params);

        const res = await axios.get(`/api/outbound/auth_url?${params}`, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        console.log("Response", res);
        onSuccess && onSuccess(res);
    }
    catch(err){
        console.log("Got error", err);
        onError && onError(err);
    }
}