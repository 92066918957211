import axios from '../axios';

export const GetAllArchiveTypes = async (data, onSuccess, onError)=>{
    try{
        const query = data;
        const res = await axios.get(`/api/archive/archive-type?flag_user=${query.flag_user}`, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        console.log("Response", res);
        onSuccess && onSuccess(res);
    }
    catch(err){
        console.log("Catch error", err);
        onError && onError(err);
    }
}