import createDataContext from './create_data_context';

const billingReducer = (state, action) => {
    switch (action.type) {
        case 'setOutstandingAmount':
            return { ...state, outstanding_amount:action.payload}
        default:
            return state;
    }
};


console.log("Billing context is being created.")
const setOutstandingAmount = (dispatch) => async (outstanding_amount) =>  {
    dispatch({
        type: 'setOutstandingAmount',
        payload: outstanding_amount
    });
    return true;

}
export const { Provider, Context } = createDataContext(
    billingReducer,
    {setOutstandingAmount},
    {outstanding_amount: 0}
);