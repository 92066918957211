// import React, { useState, useContext } from "react";
// import {
//   Button,
//   ClickAwayListener,
//   Container,
//   Grid,
//   Grow,
//   IconButton,
//   MenuItem,
//   MenuList,
//   Paper,
//   Popper,
//   Typography,
// } from "@material-ui/core";
// import AuthContext from "../../hooks/auth_context";

// import styles from "./header.module.scss";
// import logoImage from "../../assets/images/brain-logo.png";
// import MenuIcon from "@material-ui/icons/Menu";
// import PermIdentityIcon from "@material-ui/icons/PermIdentity";
// // import { Link, Redirect } from "react-router-dom";
// import {resetroute} from '../../router/navigate';

// import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

// // const menuList = [
// //   { to: "/search", label: "Search" },
// //   { to: "/policies", label: "Policies" },
// //   { to: "/reports", label: "Reports" },
// //   { to: "/export", label: "Export" },
// //   { to: "/case", label: "Case" },
// //   { to: "/tags", label: "Tags" },
// // ];

// // const adminMenuList = [{ to: "/documentapproval", label: "Document Approval" }];
// const Header = (props) => {
//   const auth = useContext(AuthContext);
//   const { history } = props;
//   const profile = auth.profile;
//   const [isActiveMenu, setIsActiveMenu] = useState(false);
//   const [menuOpen, setMenuOpen] = useState(false);
//   const [sRedirect, setRedirect] = useState(false);
//   const anchorRef = React.useRef(null);

//   const toggleMobileMenu = () => {
//     setIsActiveMenu(!isActiveMenu);
//   };
//   const logoutuser = () => {
//     auth.logoutuser();
//     setMenuOpen(false);
//     setRedirect(true);
//   };

//   const handleToggle = () => {
//     setMenuOpen((prevmenuOpen) => !prevmenuOpen);
//   };

//   const handleClose = (event) => {
//     if (anchorRef.current && anchorRef.current.contains(event.target)) {
//       return;
//     }

//     setMenuOpen(false);
//   };

//   return (
//     <div className={styles.HeaderWrap}>
//       {/* {sRedirect === true && <Redirect push={true} to="/" />} */}
//       <Container maxWidth={false} disableGutters={true}>
//         <Grid
//           container
//           direction="row"
//           justify="space-between"
//           alignItems="center"
//         >
//           <Grid item className={styles.headerLeft}>
//             <a style={{cursor: "pointer"}}
//             // to="/billing"
//             onClick={()=>resetroute()} className={styles.logowrap}>
//               <img
//                 src={logoImage}
//                 alt="ComplianceBrain"
//                 className={styles.logoimage}
//               />
//               <Typography component="h4" variant="h4">
//                 ComplianceBrain
//               </Typography>
//             </a>
//           </Grid>
//           <Grid item>
//             {profile?.company_name !== "" ? (
//               <>
//                 <img
//                   src={profile?.company_logo}
//                   alt=""
//                   className={styles.logoimageComp}
//                 />
//                 {profile?.company_name}
//               </>
//             ) : (
//               ""
//             )}
//           </Grid>
//           <Grid item className={styles.headerRight}>
//             <IconButton
//               size="small"
//               color="primary"
//               className={styles.sideMenuButton}
//               onClick={toggleMobileMenu}
//             >
//               <MenuIcon />
//             </IconButton>
//             {auth?.status === true && (
//               <div className={styles.usersWrap}>
//                 <Button
//                   ref={anchorRef}
//                   aria-controls={menuOpen ? "menu-list-grow" : undefined}
//                   aria-haspopup="true"
//                   onClick={handleToggle}
//                 >
//                   <PermIdentityIcon />
//                   {profile?.first_name || "Admin"}
//                   <ArrowDropDownIcon />
//                 </Button>
//                 <Popper
//                   open={menuOpen}
//                   anchorEl={anchorRef.current}
//                   role={undefined}
//                   transition
//                   disablePortal
//                   style={{
//                     zIndex: "1000",
//                   }}
//                 >
//                   {({ TransitionProps, placement }) => (
//                     <Grow
//                       {...TransitionProps}
//                       style={{
//                         transformOrigin:
//                           placement === "bottom"
//                             ? "center top"
//                             : "center bottom",
//                       }}
//                     >
//                       <Paper>
//                         <ClickAwayListener onClickAway={handleClose}>
//                           <MenuList
//                             autoFocusItem={menuOpen}
//                             id="menu-list-grow"
//                           >
//                             {/* <Link
//                               to="/profile"
//                               className="MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button"
//                             >
//                               Profile
//                             </Link> */}
//                             <MenuItem>
//                               Settings
//                             </MenuItem>
//                             <MenuItem onClick={(e) => logoutuser(e)}>
//                               Logout
//                             </MenuItem>
//                           </MenuList>
//                         </ClickAwayListener>
//                       </Paper>
//                     </Grow>
//                   )}
//                 </Popper>
//               </div>
//             )}

//             {/* <Button
//               className={styles.moreButtonRight}
//               variant="contained"
//               color="primary"
//             >
//               <MoreVertIcon style={{ color: "#FFFFFF" }} />
//             </Button> */}
//           </Grid>
//         </Grid>
//       </Container>
//     </div>
//   );
// };

// export default Header;

import React, { useState, useContext } from 'react';
import { AppBar, Toolbar, Typography, Box, Drawer, List, ListItem, Button, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import './header.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link } from 'react-router-dom';
import Loader from '../loader/loader';
import { sidebarWidth } from '../../constant';
import HomeSidebar from '../../assets/images/home-sidebar.png';
import SubscribeSidebar from '../../assets/images/subscribe-sidebar.svg';
import InvoiceSidebaar from '../../assets/images/invoice-sidebar.svg';
import { Logout } from '../../services/apiservices/auth';
import AuthContext from '../../hooks/auth_context';

const Header = () => {

  const [flagDrawer, setFlagDrawer] = useState(false);
  const [flagLoader, setFlagLoader] = useState(false);
  const [flagMenu, setFlagMenu] = useState(false);

  const auth = useContext(AuthContext);
  console.log(auth);

  const ChangeDrawer = () => {
    setFlagDrawer(!flagDrawer);
  }

  const ChangeFlagMenu = () => {
    setFlagMenu(!flagMenu);
  }

  const LogoutFun = () => {
    setFlagLoader(!flagLoader);

    Logout({}, (res) => {
      setFlagLoader(!flagLoader);
      console.log("Printing response of api call for logout", res);

      if (res?.data?.status === true) {
        window.location.replace(process.env.REACT_APP_LOGIN_REDIRECT);
      }
    }, (err) => {
      setFlagLoader(!flagLoader);
      console.log("Got error", err);
    })
  }

  return (
    <>
      {
        flagLoader === true && <Loader />
      }
      <AppBar id="header">
        <Toolbar style={{ display: 'flex', flexDirection: 'row', padding: '0', justifyContent: 'space-between', zIndex: 2 }}>
          <Box style={{ display: 'flex', alignItems: 'center',width:"50%" }}>
            <MenuIcon className="admin_header_menu_icon" onClick={ChangeDrawer} />
            <img className="cb-logo" src="https://storage.googleapis.com/complaincebrain-public/compliance-brain-Website/logo-colorful.png" alt="Logo" />
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center' }}>

            <Button style={{ border: 'none' }} variant="outlined" endIcon={<ArrowDropDownIcon style={{ fill: "#2b3c55" }} />} onClick={ChangeFlagMenu}>
              <Typography component="h4" style={{ color: "#000", fontSize: "15px", fontWeight: "400" }}>{auth?.profile?.first_name} {auth?.profile?.last_name}</Typography>
            </Button>

            <Menu open={flagMenu} anchorOrigin={{ vertical: 75, horizontal: "right" }} transformOrigin={{ vertical: "bottom", horizontal: "right" }} id="profile_menu" onClose={ChangeFlagMenu}>
              <MenuItem>Profile</MenuItem>
              <MenuItem onClick={LogoutFun}>Logout</MenuItem>
            </Menu>

          </Box>
        </Toolbar>
        <Drawer id="sidebar-drawer" anchor="left" open={flagDrawer} onClose={ChangeDrawer} sx={{
          width: sidebarWidth,
          zIndex: 1,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: sidebarWidth,
            boxSizing: "border-box"
          }
        }}>
          <Toolbar />
          <List>
            {/* <ListItem onClick={ChangeDrawer}>
              <Link to="/" onClick={ChangeDrawer} title="Home">
                <img src={HomeSidebar} alt="home-sidebr" style={{ width: "20px", height: "20px", position: "relative", top: "2px" }} onClick={ChangeDrawer} />
              </Link>
            </ListItem> */}
            <ListItem onClick={ChangeDrawer}>
              <Link to="/module" onClick={ChangeDrawer} title="Subscribe">
                <img src={SubscribeSidebar} alt="subscribe-sidebar"  onClick={ChangeDrawer} />
              </Link>
            </ListItem>
            <ListItem onClick={ChangeDrawer}>
              <Link to="/" onClick={ChangeDrawer} title="Invoice">
                <img src={InvoiceSidebaar} alt="invoice-sidebar"  onClick={ChangeDrawer} />
              </Link>
            </ListItem>
          </List>
        </Drawer>
      </AppBar>
    </>
  )
};

export default Header;