import React from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import GetAppIcon from "@material-ui/icons/GetApp";

const headers = [
  { id: "id", label: "" },
  { id: "date", label: "Date" },
  { id: "type", label: "Type" },
  { id: "download", label: "Download" },
];

function getModalStyle() {
  return {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "60%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
}));

const SimpleModal = ({ data }) => {
  console.log("Printing Attachments", data);
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState([]);
  const [selection, setSelection] = React.useState("Selected");

  console.log("CHK = " + JSON.stringify(checked));
  const handleChange = (id, download) => {
    setSelection("Selected");
    if (checked.some((item) => item.id === id)) {
      setChecked(checked.filter((item) => item.id !== id));
    } else {
      setChecked([...checked, { id: id, download: download }]);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const downloadFiles = () => {
    //code
  };

  const handleSelection = (event) => {
    setSelection(event.target.value);
    if (event.target.value === "All") {
      var checkedArr = [];
      data?.lines?.map((row, index) => {
        if (row.type !== "INVOICE_LINE") {
          checkedArr = [...checkedArr, { id: index, download: row.download }];
        }
        setChecked(checkedArr);
      });
    } else if (event.target.value === "None") {
      setChecked([]);
    }
  };

  const renderHeaderCells = (header, index) => {
    switch (header.id) {
      case "id":
        return (
          <Th className="pt-0 pb-0" width="220">
            <FormControl className={classes.formControl} size="small">
              <Select
                variant="outlined"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selection}
                onChange={handleSelection}
              >
                <MenuItem value="Selected">Selected</MenuItem>
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="None">None</MenuItem>
              </Select>
            </FormControl>
            <IconButton disabled={checked.length === 0} onClick={downloadFiles}>
              <GetAppIcon />
            </IconButton>
          </Th>
        );
      default:
        return <Th key={index}>{header.label}</Th>;
    }
  };

  const renderRowCells = (row, header, index) => {
    switch (header.id) {
      case "id":
        return (
          <Td className="pt-0 pb-0">
            <Checkbox
              className="pl-10"
              checked={checked.some((item) => item.id === index)}
              onChange={() => handleChange(index, row.download)}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Td>
        );
      case "date":
        return <Td>{moment(row.transaction_date).format("DD-MMM-YY")}</Td>;
      case "type":
        return <Td>{row.type}</Td>;
      case "download":
        return (
          <Td className="pt-0 pb-0">
            {row.attachment_link ?
              <a href={row.attachment_link} target="_blank">
                <IconButton>
                  <GetAppIcon />
                </IconButton>
              </a>
              : <IconButton>
                <GetAppIcon />
              </IconButton>}
          </Td>
        );
      case "interval":
        return <Td></Td>
    }
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Table className="themeTable">
        <Thead>
          <Tr>
            {headers.map((header, index) => {
              return renderHeaderCells(header, index);
            })}
          </Tr>
        </Thead>
        <Tbody>
          {data ? data.map((row, index) => {
            return (
              <Tr key={index}>
                {/*row.type !== "INVOICE_LINE" || (*/}
                <>
                  {headers.map((header) =>
                    renderRowCells(row, header, index)
                  )}
                </>
                {/*})}*/}
              </Tr>
            );
          }) : ""}
        </Tbody>
      </Table>
    </div>
  );

  return (
    <>
      <IconButton onClick={handleOpen}>
        <GetAppIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </>
  );
};

export default SimpleModal;
