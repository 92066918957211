import Cookies from "cookies-js";
const TOKENKEY = "userToken";

export function setLoginToken(token) {
  Cookies.set(TOKENKEY, token, { expires: 1 * 60 * 24 });
}

export function getLoginToken() {
  return Cookies.get(TOKENKEY);
}

export function clearLoginToken() {
  Cookies.set(TOKENKEY, "");
}
